import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { store } from '@/store/index';
import { AllActionTypes } from '@/store/action-types';
import { AuthUser, UserData } from '@/store/interfaces';
import { LOCAL_STORAGE_KEY, SERVICE_LOCAL_STORAGE_KEY } from '@/store/modules/auth/actions';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/top',
        meta: { requiresAuth: true },
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/LoginView.vue'),
    },
    {
        path: '/logout',
        name: 'Logout',
        component: () => import('../views/LogoutView.vue'),
    },
    //---------------認証コード入力からの初回登録画面
    {
        path: '/signup',
        name: 'SignupStart',
        redirect: '/entry',
        // component: () => import('../views/SignupStart.vue'),
    },
    {
        path: '/signup/code',
        name: 'SignupCode',
        component: () => import('../views/SignupCode.vue'),
    },
    {
        path: '/signup/form',
        name: 'SignupForm',
        component: () => import('../views/SignupForm.vue'),
    },
    {
        path: '/signup/confirm',
        name: 'SignupConfirm',
        component: () => import('../views/SignupConfirm.vue'),
    },
    {
        path: '/signup/complete',
        name: 'SignupComplete',
        component: () => import('../views/SignupComplete.vue'),
    },
    //---------------MN3連携からの初回登録画面
    {
        path: '/register',
        name: 'RegisterForm',
        component: () => import('../views/RegisterForm.vue'),
    },
    {
        path: '/register/confirm',
        name: 'RegisterConfirm',
        component: () => import('../views/RegisterConfirm.vue'),
    },
    {
        path: '/register/complete',
        name: 'RegisterComplete',
        component: () => import('../views/RegisterComplete.vue'),
    },
    //---------------QRコードからの初回登録画面（UNIS顧客コード知っている）
    {
        path: '/entry',
        name: 'EntryForm',
        // redirect: '/signup',
        component: () => import('../views/EntryForm.vue'),
    },
    {
        path: '/entry/confirm',
        name: 'EntryConfirm',
        component: () => import('../views/EntryConfirm.vue'),
    },
    {
        path: '/entry/notfound',
        name: 'EntryNotfound',
        component: () => import('../views/EntryNotfound.vue'),
    },
    {
        path: '/entry/code',
        name: 'EntryAuthCode',
        component: () => import('../views/EntryAuthCode.vue'),
    },
    {
        path: '/entry/agree',
        name: 'EntryAgreement',
        component: () => import('../views/EntryAgreement.vue'),
    },
    {
        path: '/entry/complete',
        name: 'EntryComplete',
        component: () => import('../views/EntryComplete.vue'),
    },
    //パスワードお忘れの方
    {
        path: '/forgot-pass',
        name: 'ForgotPassword',
        component: () => import('../views/ForgotPassword.vue'),
    },
    {
        path: '/forgot-pass/mail',
        name: 'ForgotPasswordMail',
        component: () => import('../views/ForgotPasswordMail.vue'),
    },
    {
        path: '/forgot-pass/form',
        name: 'ForgotPassResetForm',
        component: () => import('../views/ForgotPassResetForm.vue'),
    },
    {
        path: '/forgot-pass/complete',
        name: 'ForgotPasswordComplete',
        component: () => import('../views/ForgotPasswordComplete.vue'),
    },
    //ユーザーIDとパスワードを忘れた方
    {
        path: '/forgot-idpass',
        name: 'ForgotIdPassword',
        component: () => import('../views/ForgotIdPassword.vue'),
    },
    //ユーザーIDとパスワードを忘れた方>メールアドレスはわかる
    {
        path: '/forgot-idpass/mail',
        name: 'ForgotIdPasswordMail',
        component: () => import('../views/ForgotIdPasswordMail.vue'),
    },
    {
        path: '/forgot-idpass/select',
        name: 'ForgotIdPasswordSelect',
        component: () => import('../views/ForgotIdPasswordSelect.vue'),
    },
    {
        path: '/forgot-idpass/form',
        name: 'ForgotIdPasswordForm',
        component: () => import('../views/ForgotIdPasswordForm.vue'),
    },
    {
        path: '/forgot-idpass/complete',
        name: 'ForgotIdPasswordComplete',
        component: () => import('../views/ForgotIdPasswordComplete.vue'),
    },
    //ユーザーIDとパスワードを忘れた方>メールアドレスはわからない
    {
        path: '/forgot-idpass/matching/confirm',
        name: 'ForgotMatchingConfirm',
        component: () => import('../views/ForgotMatchingConfirm.vue'),
    },
    // {
    //     path: '/forgot-idpass/matching/notfound',
    //     name: 'ForgotMatchingNotFound',
    //     component: () => import('../views/ForgotMatchingNotFound.vue'),
    // },
    // {
    //     path: '/forgot-idpass/matching/code',
    //     name: 'ForgotMatchingAuthCode',
    //     component: () => import('../views/ForgotMatchingAuthCode.vue'),
    // },
    // {
    //     path: '/forgot-idpass/matching/complete',
    //     name: 'ForgotMatchingComplete',
    //     component: () => import('../views/ForgotMatchingComplete.vue'),
    // },
    // {
    //     path: '/forgot-idpass/matching/noaccount',
    //     name: 'ForgotMatchingNoAccount',
    //     component: () => import('../views/ForgotMatchingNoAccount.vue'),
    // },
    //メールアドレスを忘れた方
    // {
    //     path: '/forgot-mail',
    //     name: 'ForgotMail',
    //     component: () => import('../views/ForgotMail.vue'),
    // },
    // {
    //     path: '/forgot-mail/store',
    //     name: 'ForgotMailStore',
    //     component: () => import('../views/ForgotMailStore.vue'),
    // },
    {
        path: '/forgot-mail/mail',
        name: 'ForgotMailMail',
        component: () => import('../views/ForgotMailMail.vue'),
    },
    {
        path: '/forgot-mail/form',
        name: 'ForgotMailForm',
        component: () => import('../views/ForgotMailForm.vue'),
    },
    {
        path: '/forgot-mail/complete',
        name: 'ForgotMailComplete',
        component: () => import('../views/ForgotMailComplete.vue'),
    },
    {
        path: '/top',
        name: 'Top',
        component: () => import('../views/TopView.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/subscription/list',
        name: 'SubscriptionList',
        component: () => import('../views/SubscriptionList.vue'),
        meta: { requiresAuth: true },
    },
    //ユーザーIDとパスワードを設定
    {
        path: '/setting-idpass',
        name: 'SettingIdPasswordForm',
        component: () => import('../views/SettingIdPasswordForm.vue'),
    },
    {
        path: '/setting-idpass/confirm',
        name: 'SettingIdPasswordConfirm',
        component: () => import('../views/SettingIdPasswordConfirm.vue'),
    },
    {
        path: '/setting-idpass/complete',
        name: 'SettingIdPasswordComplete',
        component: () => import('../views/SettingIdPasswordComplete.vue'),
    },
    //---------------請求書、契約書控え確認
    // {
    //     path: '/contract',
    //     redirect: '/invoice',
    //     meta: { requiresAuth: true },
    // },
    {
        path: '/invoice',
        name: 'InvoiceTotal',
        component: () => import('../views/InvoiceTotal.vue'),
        meta: { requiresAuth: true },
    },

    {
        path: '/invoice/detail/debit',
        name: 'InvoiceDirectDebit',
        component: () => import('../views/InvoiceDirectDebit.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/invoice/document', //請求書
        name: 'InvoiceDocument',
        component: () => import('../views/InvoiceDocument.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/invoice/target/update',
        name: 'InvoiceUpdate',
        component: () => import('../views/InvoiceUpdate.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/upay/list',
        name: 'UPayList',
        component: () => import('../views/UPayList.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/upay/detail/:id',
        name: 'UPayDetail',
        component: () => import('../views/UPayDetail.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/upay/next/:id',
        name: 'UPayNext',
        component: () => import('../views/UPayNext.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/pdf/payment/pguq/:id',
        name: 'UPayPdfPreview',
        component: () => import('../views/UPayPdfPreview.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '/pdf/payment/souki/:id/:date',
        name: 'UPayNextPdfPreview',
        component: () => import('../views/UPayNextPdfPreview.vue'),
        meta: { requiresAuth: false },
    },
    //契約
    {
        path: '/document/application',
        name: 'DocumentApplication',
        component: () => import('../views/DocumentApplication.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/document/delivery',
        name: 'DocumentDeliveryCompletion',
        component: () => import('../views/DocumentDeliveryCompletion.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/document/contract',
        name: 'DocumentContractConfirm',
        component: () => import('../views/DocumentContractConfirm.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/contract/detail',
        name: 'ContractDetail',
        component: () => import('../views/ContractDetail.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/contract/view',
        name: 'ContractView',
        component: () => import('../views/ContractView.vue'),
        meta: { requiresAuth: true },
    },
    //アカウント
    {
        path: '/account',
        redirect: '/account/info',
        meta: { requiresAuth: true },
    },
    {
        path: '/account/info',
        name: 'AccountInfo',
        component: () => import('../views/AccountInfo.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/account/share',
        name: 'ShareMember',
        component: () => import('../views/ShareMember.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/account/search',
        name: 'StoreSearch',
        component: () => import('../views/StoreSearch.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/account/search-result',
        name: 'StoreSearchResult',
        component: () => import('../views/StoreSearchResult.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/account/branch/store/admin',
        name: 'BranchStoreAdmin',
        component: () => import('../views/BranchStoreAdmin.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/account/branch/store/share',
        name: 'BranchStoreShare',
        component: () => import('../views/BranchStoreShare.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/share/detail',
        name: 'ShareMemberDetail',
        component: () => import('../views/ShareMemberDetail.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/account/branch/store/share/detail',
        name: 'BranchStoreShareDetail',
        component: () => import('../views/BranchStoreShareDetail.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/inquiries',
        name: 'InquiriesQA',
        component: () => import('../views/InquiriesQA.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/info/list',
        name: 'InfoList',
        component: () => import('../views/InfoList.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/info/detail/:id',
        name: 'InfoDetail',
        component: () => import('../views/InfoDetail.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/lp/lp-ueyes',
        name: 'LpUeyes',
        component: () => import('../views/LpUeyes.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/lp/lp-uregi',
        name: 'LpUregi',
        component: () => import('../views/LpUregi.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/lp/lp-uplink',
        name: 'LpUplink',
        component: () => import('../views/LpUplink.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/lp/lp-umusic',
        name: 'LpUmusic',
        component: () => import('../views/LpUmusic.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/lp/lp-uphone',
        name: 'LpUphone',
        component: () => import('../views/LpUphone.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/lp/lp-uair',
        name: 'LpUair',
        component: () => import('../views/LpUair.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/lp/lp-uspot',
        name: 'LpUspot',
        component: () => import('../views/LpUspot.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/inquiry',
        name: 'NewStoreForm',
        component: () => import('../views/NewStoreForm.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/inquiry/confirm',
        name: 'NewStoreConfirm',
        component: () => import('../views/NewStoreConfirm.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/inquiry/complete',
        name: 'NewStoreComplete',
        component: () => import('../views/NewStoreComplete.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/tutorial',
        name: 'TutorialView',
        component: () => import('../views/TutorialView.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/error/expired',
        name: 'ErrorLinkExpired',
        component: () => import('../views/ErrorLinkExpired.vue'),
    },
    {
        path: '/user/update-mail/complete',
        name: 'UserUpdateMailComplete',
        component: () => import('../views/UserUpdateMailComplete.vue'),
    },
    {
        path: '/weekly_weather',
        name: 'WeeklyWeatherForecast',
        component: () => import('../views/WeeklyWeatherForecast.vue'),
        meta: { requiresAuth: true },
    },
    //取扱高推移
    {
        path: '/transaction-volume',
        name: 'TransactionVolume',
        component: () => import('../views/TransactionVolume.vue'),
        meta: { requiresAuth: true },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});

const INPUT_AUTH_CODE = 'inputAuthCode'; //signup/code 認証コード入力
const RESET_PW_SHARE_ACCOUNT = 'resetBySharePassword'; //forgot-pass/form 新しいパスワード
const CREATE_ACCOUNT = 'createAccount'; ///signup/form 初回登録画面
const CREATE_SHARE_ACCOUNT = 'createShareAccount'; ///signup/form 初回登録画面
const RESET_PW_USERNAME = 'resetByUserName'; //forgot-pass/form 新しいパスワード
const RESET_PW_DESTINATION = 'resetByDestination'; ///forgot-idpass/select
const RESET_PW_SECURITY_ANSWER = 'resetBySecurityAnswer'; //forgot-mail/form
const UPDATE_MAIL = 'updateMailAddress'; //forgot-mail/form

router.beforeResolve(async (to, from, next) => {
    if (to.path === '/token') {
        const token = to.query.token;
        const umid = to.query.umid;
        const u2 = to.query.u2;
        const expired = to.query.expired;
        const refreshToken = to.query.refreshToken;

        console.log(umid);

        if (token && umid && u2) {
            const claim = await store.dispatch(AllActionTypes.AUTH_TOKEN, {
                token: token.toString(),
                umid: umid,
            });
            if (claim) {
                const userAttributes = {
                    UserAttributes: [
                        {
                            Name: 'sub',
                            Value: claim.sub,
                        },
                        {
                            Name: 'email_verified',
                            Value: claim.email_verified,
                        },
                        {
                            Name: 'email',
                            Value: claim.email,
                        },
                    ],
                    Username: claim.username,
                };

                let localData = localStorage.getItem(SERVICE_LOCAL_STORAGE_KEY);
                let data: AuthUser = {
                    idToken: '',
                    accessToken: '',
                    refreshToken: '',
                    expired: '',
                    umid: '',
                    isKeepLogin: false,
                };

                if (localData) {
                    const authData = JSON.parse(localData);
                    data = authData.auth as AuthUser;
                    console.log(data);
                    if (data.umid === umid) {
                        store.commit('SET_AUTH', data as AuthUser);
                        store.commit('SET_AUTH_USER', authData.user);
                    } else {
                        localData = null;
                    }
                }

                if (!localData) {
                    data.idToken = token.toString();
                    data.refreshToken = refreshToken ? refreshToken.toString() : '';
                    data.expired = expired ? expired.toString() : '';
                    data.umid = umid.toString();
                    const user = await store.dispatch(AllActionTypes.GET_AUTH_USER, {
                        userName: claim.username,
                    });
                    store.commit('SET_AUTH', data);
                    store.commit('SET_AUTH_USER', user);
                    localStorage.setItem(SERVICE_LOCAL_STORAGE_KEY, JSON.stringify({ auth: data, user: user }));
                    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ auth: data, user: user }));
                }

                sessionStorage.setItem(`CognitoIdentityServiceProvider.${claim.aud}.${claim.username}.accessToken`, data.accessToken ?? '');
                sessionStorage.setItem(`CognitoIdentityServiceProvider.${claim.aud}.${claim.username}.refreshToken`, data.refreshToken ?? '');
                sessionStorage.setItem(`CognitoIdentityServiceProvider.${claim.aud}.${claim.username}.idToken`, token.toString());
                sessionStorage.setItem(`CognitoIdentityServiceProvider.${claim.aud}.${claim.username}.userData`, JSON.stringify(userAttributes));
                sessionStorage.setItem(`CognitoIdentityServiceProvider.${claim.aud}.${claim.username}.LastAuthUser`, claim.username);
                sessionStorage.setItem(`CognitoIdentityServiceProvider.${claim.aud}.${claim.username}.clockDrift`, '0');

                return next({ path: '/' });
            }
        }
        return next({ path: '/' });
    }
    if (to.path === '/signed') {
        // const umid = to.query.umid;
        const type = to.query.type;
        const umid = to.query.umid;
        const destination = to.query.destination;
        const service = to.query.service;
        // console.log(type);
        let path = '';
        switch (type) {
            case INPUT_AUTH_CODE:
                path = `/signup/code?umid=${umid}`;
                break;
            case RESET_PW_SHARE_ACCOUNT:
                path = `/forgot-pass/form?umid=${umid}`;
                break;
            case CREATE_ACCOUNT:
                // eslint-disable-next-line no-case-declarations
                const user = await store.dispatch(AllActionTypes.GET_UMID, {
                    umid: umid,
                    unis: false,
                });
                if (user && user.account && user.account.userName) {
                    path = `/error/expired`;
                    break;
                }
                path = `/register?umid=${umid}`;
                break;
            case CREATE_SHARE_ACCOUNT:
                // eslint-disable-next-line no-case-declarations
                const share = await store.dispatch(AllActionTypes.GET_UMID, {
                    umid: umid,
                    unis: false,
                });
                // console.log(umid);
                // console.log(share.account);
                if (share && share.account && share.account.userName) {
                    path = `/error/expired`;
                    break;
                }
                path = `/register?umid=${umid}&service=${service}&user=share`;
                break;
            case RESET_PW_USERNAME:
                path = `/forgot-pass/form?umid=${umid}`;
                break;
            case RESET_PW_DESTINATION:
                path = `/forgot-idpass/select?destination=${destination}`;
                break;
            case RESET_PW_SECURITY_ANSWER:
                path = `/forgot-mail/form?umid=${umid}`;
                break;
            case UPDATE_MAIL:
                path = `/user/update-mail/complete?umid=${umid}`;
                break;
        }
        return next(path);
    }
    // console.log(to.fullPath);
    if (to.fullPath === '/login') {
        const localAuth = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (localAuth) {
            // console.log('localAuth');
            const data = JSON.parse(localAuth);
            store.commit('SET_AUTH', data.auth as AuthUser);
            store.commit('SET_AUTH_USER', data.user as UserData);
            return next({ path: '/' });
        }
        if (store.getters.auth) {
            return next({ path: '/' });
        }
    }
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        //ここで統一的にチェックをする
        // try {
        //     await Auth.currentSession();
        // } catch (error) {
        //     console.error(error);
        //     localStorage.setItem(LOCAL_STORAGE_KEY, '');
        //     localStorage.setItem(SERVICE_LOCAL_STORAGE_KEY, '');
        //     sessionStorage.clear();
        //     localStorage.clear();
        //     store.commit('SET_AUTH', undefined);
        //     store.commit('SET_AUTH_USER', undefined);
        //     return next({ path: '/login' });
        // }
        console.log('router requiresAuth');
        const localAuth = localStorage.getItem(LOCAL_STORAGE_KEY);
        console.log(localAuth);
        if (localAuth) {
            const data = JSON.parse(localAuth);
            const auth = data.auth as AuthUser;
            const user = data.user as UserData;
            console.log(auth);
            console.log(auth.isKeepLogin);
            if (auth.isKeepLogin) {
                console.log('keepLogin');
                //AuthUserはここで最新化する
                await store.dispatch(AllActionTypes.TOKEN_REFRESH, { auth, user });
                store.commit('SET_AUTH_USER', user as UserData);
                store.commit('SET_AUTH', auth as AuthUser);
            } else {
                console.log('signOut');
                await store.dispatch(AllActionTypes.SIGN_OUT);
                return next({ path: '/login' });
            }
        }
        if (!store.getters.auth) {
            console.log('[NG] Router beforeResolve auth NG');
            await store.dispatch(AllActionTypes.SIGN_OUT);
            return next({ path: '/login' });
        }
    }
    return next();
});
export default router;
